import React from "react"
import { Typography } from "@material-ui/core"
import { Grid } from "@material-ui/core"
import styled from "styled-components"

const SfGrid = styled(Grid)`
  margin: 2rem 0 2rem 0;
`

interface Title {
  name: string
}

const TitleFilterPage = ({ name }: Title) => {
  return (
    <SfGrid container justify={"center"}>
      <Grid item sm={12}>
        <Typography variant={"h3"} component={"h1"} align={"center"}>
          Toutes les références : {name}
        </Typography>
      </Grid>
    </SfGrid>
  )
}

export default TitleFilterPage
