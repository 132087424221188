import { Grid } from "@material-ui/core"
import RealisationCard from "../../components/CardRealisation"
import React from "react"
import { graphql } from "gatsby"
import SEO from "../../components/seo"
import Layout from "../../components/Layout"
import TitleFilterPage from "../../components/TitleFilterPage"

const RealisationListByProduct = ({ pageContext, data }) => {
  return (
    <Layout>
      <SEO title="Home" />
      <TitleFilterPage name={pageContext.produit} />
      <Grid item lg={8}>
        <Grid container spacing={2}>
          {data.allStrapiRealisation.edges.map(({ node }) => (
            <Grid item sm={6}>
              <RealisationCard details={node} />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Layout>
  )
}

export default RealisationListByProduct

export const realisationsQuery = graphql`
  query real_product_query($produit: String) {
    allStrapiRealisation(
      sort: { fields: created_at, order: DESC }
      filter: { produits: { elemMatch: { name: { in: [$produit] } } } }
    ) {
      edges {
        node {
          id
          name
          desc
          produits {
            name
          }
          isolation
          style
          ville {
            name
          }
          url
          photo {
            url
          }
          legacyimage {
            image
          }
        }
      }
    }
  }
`
